import http from './request'


// 快巴士
export const weblogincode = (params, callback) => { http.post('/index/weblogincode', params).then(res => { callback(res) }) }//获取登录二维码
export const checklogingrcode = (params, callback) => { http.post('/index/checkloginqrcode', params).then(res => { callback(res) }) }//心跳查询扫码
export const getcode = (params, callback) => { http.post('/member/getcode', params).then(res => { callback(res) }) }//获取验证码
export const weblogin = (params, callback) => { http.post('/index/weblogin', params).then(res => { callback(res) }) }//手机登录
export const getuser = (params, callback) => { http.post('/membercenter/getuser', params).then(res => { callback(res) }) }//个人信息
export const homeList = (params, callback) => { http.post('/index/getlist', params).then(res => { callback(res) }) }//首页列表
export const getIndexShow = (params, callback) => { http.post('/Webapi/getIndexShow', params).then(res => { callback(res) }) }//首页车辆
export const initcarconfig = (params, callback) => { http.post('/member/initcarconfig', params).then(res => { callback(res) }) }//座位
export const getcity = (params, callback) => { http.post('/member/getcity', params, false).then(res => { callback(res) }) }//地区
export const getNextCity = (params, callback) => { http.post('/member/getNextCity', params).then(res => { callback(res) }) }//地区
export const getIndexShowInfo = (params, callback) => { http.post('/Webapi/getIndexShowInfo', params).then(res => { callback(res) }) }//车辆详情
export const userhandle = (params, callback) => { http.post('/membercenter/userhandle', params).then(res => { callback(res) }) }//发布提交
export const uploadurl = (params, callback) => { http.post('/membercenter/getuploadurl2', params).then(res => { callback(res) }) }//上传
export const upuser = (params, callback) => { http.post('/membercenter/upuser', params).then(res => { callback(res) }) }//头像提交
export const upphone = (params, callback) => { http.post('/membercenter/upphone', params).then(res => { callback(res) }) }//头像提交
export const updaoyou = (params, callback) => { http.post('/membercenter/updaoyou', params).then(res => { callback(res) }) }//个人简介
export const getCateList = (params, callback) => { http.post('/Webapi/getCateList', params).then(res => { callback(res) }) }//列表页车辆列表
export const getOrderList = (params, callback) => { http.post('/Webapi/getOrderList', params).then(res => { callback(res) }) }//订单列表
export const clearorder = (params, callback) => { http.post('/membercenter/clearorder', params).then(res => { callback(res) }) }//订单停止
export const delOrder = (params, callback) => { http.post('/membercenter/delOrder', params).then(res => { callback(res) }) }//订单停止
export const reorder = (params, callback) => { http.post('/membercenter/reorder', params).then(res => { callback(res) }) }//订单继续
export const needinfo = (params, callback) => { http.post('/member/needinfo', params).then(res => { callback(res) }) }//订单编辑
export const needdaoyoulist = (params, callback) => { http.post('/membercenter/needdaoyoulist', params).then(res => { callback(res) }) }//选他中标按钮显示隐藏
export const selecteddaoyou = (params, callback) => { http.post('/membercenter/selecteddaoyou', params).then(res => { callback(res) }) }//选他中标
export const payCode = (params, callback) => { http.post('/Webapi/payCode', params).then(res => { callback(res) }) }//支付二维码
export const checkPay = (params, callback) => { http.post('/Webapi/checkPay', params).then(res => { callback(res) }) }//支付检测
export const getPushSort = (params, callback) => { http.post('/Webapi/getPushSort', params).then(res => { callback(res) }) }//侧边栏推荐
export const geteoreord = (params, callback) => { http.post('/Membercenter/getcarcard', params).then(res => { callback(res) }) }//图片识别营业执照
export const initVerify = (params, callback) => { http.post('/Webapi/initVerify', params).then(res => { callback(res) }) }//认证信息
export const upVerify = (params, callback) => { http.post('/Webapi/upVerify', params).then(res => { callback(res) }) }//提交认证信息
export const newsInfo = (params, callback) => { http.post('/index/newsInfo', params).then(res => { callback(res) }) }//新闻详情
export const getNewsList = (params, callback) => { http.post('/index/getNewsList', params).then(res => { callback(res) }) }//新闻列表接口
export const getonePage = (params, callback) => { http.post('/index/getonePage', params).then(res => { callback(res) }) }//底部
export const fapiaoList = (params, callback) => { http.post('/membercenter/fapiaolist', params).then(res => { callback(res) }) }//底部
