<template>
  <div class="login">
    <div class="login-title">
      <img @click="homeChange" src="../img/logo.png" alt />
    </div>
    <div class="login-box">
      <!-- <div class="login-box-picture">
        <img src="../images/login-img.png" alt />
      </div>-->
      <div class="login-box-frame">
        <div class="login-box-frame-title" style="display: flex">
          <div>
            <div
              style="padding: 0 15px; cursor: pointer"
              @click="typeChange('0')"
            >
              账号登录
            </div>
            <div v-if="!typeNum" class="login-box-frame-title-wire"></div>
          </div>

          <div>
            <div
              style="padding: 0 15px; cursor: pointer"
              @click="typeChange('1')"
            >
              微信扫码登录
            </div>
            <div
              v-if="typeNum"
              class="login-box-frame-title-wire"
              style="width: 135px"
            ></div>
          </div>
        </div>
        <!-- 手机号登录 -->
        <template v-if="!typeNum">
          <div class="login-box-frame-account">
            <img src="../images/account.png" alt />
            <input
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="phone"
              name
              placeholder="请输入手机号"
            />
          </div>
          <div class="login-box-frame-account">
            <img src="../images/password.png" alt />
            <div class="login-box-frame-account-password">
              <input
                oninput="value=value.replace(/[^\d]/g,'')"
                v-model="code"
                placeholder="请输入验证码"
              />
              <div class="login-box-frame-account-password-code">
                <p v-if="time === -1" @click="codeChange">获取验证码</p>
                <p v-else>{{ time }} s后重发</p>
              </div>
            </div>
          </div>
          <div class="login-box-frame-but" @click="routerChange">立即登录</div>
          <div class="login-box-frame-text">
            <p>未注册手机验证后自动登录</p>
            <p>
              登录或注册即代表同意
              <span>
                <el-button
                  type="text"
                  style="color: #c58435"
                  @click="popShow = !popShow"
                  >用户协议</el-button
                >
              </span>
            </p>
          </div>
        </template>
        <!-- 微信扫码登录 -->
        <template v-if="typeNum">
          <div class="wechat">
            <div class="wechat_yard">
              <img width="100%" :src="qrcode" alt />
            </div>
            <!-- <div class="wechat_text">使用微信扫码登录</div> -->
          </div>
        </template>
      </div>
    </div>

    <div class="pop" v-show="popShow">
      <div class="pop-box">
        <div class="pop-box-img">
          <img @click="popShow = !popShow" src="../images/close.png" alt />
        </div>
        <div class="pop-box-title">用户服务协议</div>
        <div class="pop-box-text">
          <p v-html="content"></p>
        </div>
        <div class="pop-box-but" @click="popShow = !popShow">知道了</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  weblogin,
  getcode,
  weblogincode,
  checklogingrcode,
  getuser,
  uploadurl,
} from "../utils/api";
export default {
  name: "loginView",
  data() {
    return {
      popShow: false,
      time: -1,
      phone: "",
      code: "",
      content: "",
      typeNum: 1,
      qrcode: "", //微信二维码
      logincode: "",
      timer: "",
      userid: "",
    };
  },
  created() {
    // 页面头部标题
    document.title = this.$route.meta.title;
    this.init();
    this.timeChange();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    typeChange(val) {
      if (val == "0") {
        this.typeNum = 0;
        clearInterval(this.timer);
      } else {
        this.typeNum = 1;
        this.timeChange();
      }
    },
    // 心跳
    timeChange() {
      this.timer = setInterval(() => {
        checklogingrcode(
          {
            logincode: this.logincode,
          },
          (res) => {
            if (res.code === 0) {
              if (res.data) {
                clearInterval(this.timer);

                if (res.data.token) {
                  localStorage.setItem("token", res.data.token);

                  getuser({}, (res) => {
                    if (res.code == 0) {
                      this.$store.commit("phone", res.data.phone);
                      this.$store.commit("headimgurl", res.data.headimgurl);
                      this.$store.commit("nickname", res.data.nickname);
                      localStorage.setItem("phone", res.data.phone);
                      localStorage.setItem("avatar", res.data.headimgurl);
                      localStorage.setItem("nickname", res.data.nickname);
                      // 获取上传地址
                      uploadurl({ iswed: 1 }, (url) => {
                        localStorage.setItem("uploadurl", url.data.uploadurl);
                        this.$store.commit("uploadurl", url);
                      });
                      this.$router.push({
                        name: "home",
                      });
                    }
                  });
                } else {
                  this.userid = res.data.userid;
                  this.typeNum = 0;
                }
              }
            }
          }
        );
      }, 1000);
    },
    // 获取二维码
    init() {
      let keys = window.localStorage.getItem("keys");
      if (!keys) {
        keys = "k" + Math.random().toString().replaceAll(".");
        window.localStorage.setItem("keys", keys);
      }
      weblogincode({ keys: keys }, (res) => {
        if (res.code === 0) {
          this.qrcode = res.data.qrcode;
          this.logincode = res.data.logincode;
        }
      });
    },
    // 登录
    routerChange() {
      weblogin(
        { phone: this.phone, code: this.code, userid: this.userid },
        (res) => {
          if (res.code === 0) {
            if (res.data.token) {
              this.$message({
                showClose: true,
                message: res.msg,
                type: "success",
              });
              localStorage.setItem("token", res.data.token);
              this.$router.push({
                name: "home",
              });
            } else {
              this.userid = res.data.userid;
              this.typeNum = 1;
              // 获取二维码
              weblogincode({ userid: this.userid }, (res) => {
                if (res.code === 0) {
                  this.qrcode = res.data.qrcode;
                  this.logincode = res.data.logincode;
                  // 心跳查询
                  this.timeChange();
                }
              });
            }
          }
        }
      );
    },
    // 获取验证码
    codeChange() {
      var verify =
        /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/;
      if (verify.test(this.phone)) {
        this.time = 60;
        var setTime = setInterval(() => {
          --this.time;
          // console.log(this.time);
          if (this.time < 0) {
            clearInterval(setTime);
          }
        }, 1000);
        getcode({ phone: this.phone }, (res) => {
          // console.log(res);
          if (res.code === 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "success",
            });
          } else {
            clearInterval(setTime);
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          showClose: true,
          message: "手机号有误",
          type: "warning",
        });
      }
    },
    homeChange() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>
<style scoped lang="scss">
.login {
  width: 100%;
  height: 100vh;
  // background: url("../img/login-bg.png") center;
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  position: relative;
  outline: hidden;

  &-title {
    width: 13%;
    margin: 24px 0 28px 50px;
    > img {
      width: 100%;
      // height: 77px;
      margin: auto;
      cursor: pointer;
    }
  }

  &-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &-picture {
      > img {
        width: 489px;
        height: 476px;
      }
    }
    &-frame {
      width: 530px;
      height: 634px;
      background-color: #fff;
      border-radius: 12px;
      margin-left: 109px;
      padding: 83px 58px 0;

      &-title {
        padding-bottom: 30px;
        border-bottom: 1px solid #dddddd;
        position: relative;
        margin-bottom: 34px;
        &-wire {
          width: 96px;
          height: 3px;
          background-color: #c58435;
          position: absolute;
          bottom: -2px;
        }
      }
      &-account {
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: 44px;
        padding-bottom: 31px;
        border-bottom: 1px solid #dddddd;
        font-size: 18px;
        font-weight: 400;
        > img {
          width: 13px;
          height: 20px;
          margin-right: 20px;
        }
        > input {
          outline: none;
          border: none;
          font-size: 18px;
        }
        &-password {
          width: 100%;
          display: flex;
          justify-content: space-between;
          > input {
            outline: none;
            border: none;
            font-size: 18px;
          }
          &-code {
            width: 130px;
            color: #c58435;
            padding-left: 13px;
            border-left: 1px solid #dddddd;
            cursor: pointer;
          }
        }
      }
      &-but {
        width: 415px;
        height: 66px;
        color: rgb(255, 255, 255);
        font-size: 20px;
        font-weight: 400;
        line-height: 66px;
        text-align: center;
        margin-top: 60px;
        cursor: pointer;
        // box-shadow: 1px 1px 10px 0px rgba(12, 34, 65, 0.23);
        // background: linear-gradient(135deg, #3b7ad8, #10a3e4);
        background: url(../img/login--btn-bg.png) no-repeat;
        background-size: cover;
      }
      &-text {
        font-size: 16px;
        font-weight: 400;
        color: #666666;
        margin-top: 62px;
        text-align: center;
        > p > span {
          color: #3a7bd8;
          cursor: pointer;
        }
      }
    }
  }
  &-bottom {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #999999;
  }
}
.pop {
  position: absolute;
  top: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  &-box {
    width: 1000px;
    height: 747px;
    background-color: #fff;
    &-img {
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;
      padding-top: 22px;
      padding-right: 22px;
      > img {
        width: 17px;
        height: 17px;
      }
    }
    &-title {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      margin-top: 21px;
      margin-bottom: 25px;
    }
    &-text {
      height: 448px;
      padding: 0 59px 0 56px;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      line-height: 36px;
      overflow-y: auto;
    }
    &-but {
      width: 414px;
      height: 66px;
      margin: 59px auto 66px;
      box-shadow: 1px 1px 10px 0px rgba(12, 34, 65, 0.23);
      background: linear-gradient(135deg, #3b7ad8, #10a3e4);
      font-size: 20px;
      font-weight: 400;
      color: #fff;
      text-align: center;
      line-height: 60px;
    }
  }
}
:deep .el-message-box__content {
  //  width: 980px;
  color: red;
}
.wechat {
  &_yard {
    width: 283px;
    height: 283px;
    overflow: hidden;
    margin: auto;
  }
  &_text {
    text-align: center;
    color: #7b7b7b;
    font-size: 16px;
    font-weight: 400;
    margin-top: 52px;
  }
}
</style>